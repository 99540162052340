import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  plugins,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import {
  getDashboardData,
  getDashboardDataWithType,
  getDashboardMonthData,
  getDashboardColumnsData,
  setUrlMonithorService,
} from "../../actions/monithor.action";
import ControlledInputRoundedForm from "../../components/InputForm/ControlledInputRoundedForm";
import { Grid } from "@material-ui/core";
import { useForm } from "react-hook-form";
import IconEspecialButton from "../../components/ButtonForm/IconEspecialButton.component";
import { Search, Launch } from "@material-ui/icons";
import CustomTable from "../../components/Table/CustomTable.component";
import { useSelector } from "react-redux";

ChartJS.plugins.register({
  id: "dashboard",
  ArcElement,
  Tooltip,
  Legend,
  plugins,
});

export function MonitoringDashBoard() {
  const [dashboardData, setDashboardData] = useState([]);
  const [monthData, setMonthData] = useState([]);
  const [objectTypeData, setObjectTypeData] = useState([]);
  const [isMonthData, setIsMonthData] = useState(false);
  const [isObjectTypeData, setIsObjectTypeData] = useState(false);
  const [currentIntegration, setCurrentIntegration] = useState("");
  const [currentMonth, setCurrentMonth] = useState("");
  const [option, setOption] = useState(null);
  const [records, setRecords] = useState([]);
  const [columns, setColumns] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [isSecondDataTable, setIsSecondDataTable] = useState(false);
  const [isThirdDataTable, setIsThirdDataTable] = useState(false);
  const [isData, setIsData] = useState(false);
  let params = new URLSearchParams(window.location.search);
  const [environmentURL, setEnvironmentURL] = useState(
    params.get("env") ? `https://${params.get("env")}.parameta.co` : ""
  );
  const [fullURL] = useState(
    params.get("fullurl") ? params.get("fullurl") : ""
  );
  const [version, setVersion] = useState(parseInt(params.get("ver") ?? "1"));

  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const handleDataWithDate = () => {
    setIsObjectTypeData(false);
    setIsMonthData(false);
    setIsSecondDataTable(false);
    setIsThirdDataTable(false);
    fetchData(control.getValues().dateStart, control.getValues().dateEnd);
  };

  const getUserFriendlyName = (key) => {
    if (key === "OBJECT_TYPE") return "Integración";

    if (key === "N") return "Cantidad de integraciones";

    if (key === "EVENT_CODE") return "Tipo de evento";

    if (key === "MONTH_") return "Mes";
  };

  const arrayToCSV = (array) => {
    if (!array.length) return '';

    const headers = Object.keys(array[0]);
    const userFriendlyHeaders = headers.map(key => getUserFriendlyName(key));
    //console.log(headers);

    const csvRows = [
      userFriendlyHeaders.join(','),
      ...array.map(row => headers.map(header => 
        `"${String(row[header]).replace(/"/g, '""')}"`
      ).join(','))
    ];

    return csvRows.join('\n');
  }

  const downloadCSV = (csvString, filename) => {
    const blob = new Blob([csvString], {type: 'text/csv'});
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  const exportCSV = (data) => {
    const csvString = arrayToCSV(data);
    const currentDate = getCurrentDate();
    const filename = `DASHBOARD_${currentDate}.csv`;
    downloadCSV(csvString, filename);
  }

  async function fetchData(startDate, endDate) {
    try {
      const fetchedData = await getDashboardData(startDate, endDate);

      const data = fetchedData.datasets[0].data;
      const labels = fetchedData.labels;
      const result = labels.map((label, index) => ({
        OBJECT_TYPE: label,
        N: data[index].toString(),
      }));

      setDashboardData(fetchedData);
      setDataTable(result);
    } catch (error) {
      console.error("Error al fetchData:", error);
    }
  }

  useEffect(() => {
    try {
      if (dashboardData.labels.length == 0) {
        setIsData(false);
      } else {
        setIsData(true);
      }
    } catch (e) {
      console.error(e);
    }
  }, [dashboardData]);

  async function fetchDataWithType(
    shouldUpdateTable,
    startDate,
    endDate,
    type,
    month
  ) {
    try {
      const fetchedData = await getDashboardDataWithType(
        startDate,
        endDate,
        type,
        month
      );

      const data = fetchedData.datasets[0].data;
      const labels = fetchedData.labels;
      const result = labels.map((label, index) => ({
        OBJECT_TYPE: type,
        EVENT_CODE: label,
        N: data[index].toString(),
        MONTH_: month,
      }));

      if (shouldUpdateTable) setDataTable(result);

      setObjectTypeData(fetchedData);
      setCurrentIntegration(type);
      setCurrentMonth(month);
      setIsObjectTypeData(true);
    } catch (error) {
      console.error("Error al fetchDataWithType:", error);
    }
  }

  async function fetchMonthData(
    shouldUpdateTable,
    startDate,
    endDate,
    type,
    eventCode,
    month
  ) {
    try {
      const fetchedData = await getDashboardMonthData(
        startDate,
        endDate,
        type,
        eventCode,
        month
      );
      const data = fetchedData.datasets[0].data;
      const labels = fetchedData.labels;

      const result = labels.map((label, index) => ({
        OBJECT_TYPE: type,
        MONTH_: label,
        N: data[index].toString(),
        EVENT_CODE: eventCode,
      }));

      setMonthData(fetchedData);

      if (shouldUpdateTable) setDataTable(result);

      setIsMonthData(true);
      fetchRecords();
      return fetchedData;
    } catch (error) {
      console.error("Error al fetchMonthData:", error);
    }
  }

  async function fetchRecords() {
    setRecords(await getDashboardColumnsData());
  }

  const { getEnterpriseByIDResponse } = useSelector(
    (store) => store.enterpriseReducer
  );

  useEffect(() => {
    if (getEnterpriseByIDResponse.monitoringService) {
      setEnvironmentURL(getEnterpriseByIDResponse.monitoringService);
      setVersion(getEnterpriseByIDResponse.monitoringVersion);
    }

    if (version && (environmentURL || fullURL)) {
      setUrlMonithorService(fullURL ? fullURL : environmentURL);
      fetchData(control.getValues().dateStart, control.getValues().dateEnd);
      fetchRecords();
    }
  }, [getEnterpriseByIDResponse, version, environmentURL, fullURL]);

  const { control, errors } = useForm({
    defaultValues: {
      dateStart: "",
      dateEnd: getCurrentDate(),
    },
    shouldUnregister: false,
    mode: "onChange",
  });

  useEffect(() => {
    try {
      if (records.length) {
        setIsData(true);
        const newColumns = [];
        const newQueriesColumns = [];
        records.forEach((key) => {
          if (
            key.id !== "OBJECT_STATUS" &&
            key.id !== "OBJECT_DATE" &&
            key.id !== "COMPANY" &&
            key.id !== "YEAR_" &&
            key.id !== "DAY_"
          ) {
            if (key.id === "EVENT_CODE" && !isSecondDataTable) return;

            if (key.id === "MONTH_" && !isThirdDataTable) return;
            newColumns.push({
              id: key.id,
              label: getUserFriendlyName(key.id),
              minWidth: 40,
              alignHeader: "left",
              haveDropDown: true,
              wordLength: 100,
            });
            let type = typeof records[0][key];
            newQueriesColumns.push({
              fieldName: key.id,
              fieldValue: "",
              comparision: "6",
              groupAction: "9",
              type: type,
            });
          }
        });
        newColumns.push({
          id: "action",
          label: "",
          align: "left",
          tooltipMessage: "registro",
          withStateIconByValue: true,
          withDeleteIconAlwaysVisible: true,
          withIcon: true,
          format: (value) => (value === 1 || value === false ? false : true),
        });
        setColumns(newColumns);
        records.forEach((rec, index) => {
          rec.id = rec.id ?? index + 1;
        });
      }
    } catch (err) {
      console.error(err);
    }
  }, [records, isSecondDataTable, isThirdDataTable, currentMonth]);

  const integrationChartOptions = {
    onClick: (e, element) => {
      if (element.length > 0) {
        let ind = element[0]._index;
        setIsSecondDataTable(false);
        setIsThirdDataTable(true);
        fetchDataWithType(
          false,
          control.getValues().dateStart,
          control.getValues().dateEnd,
          dashboardData.labels[ind]
        );
        fetchMonthData(
          true,
          control.getValues().dateStart,
          control.getValues().dateEnd,
          dashboardData.labels[ind]
        );
      }
    },
  };

  const monthChartOptions = {
    onClick: (e, element) => {
      if (element.length > 0) {
        let ind = element[0]._index;
        setIsSecondDataTable(true);
        setIsThirdDataTable(true);
        fetchDataWithType(
          true,
          control.getValues().dateStart,
          control.getValues().dateEnd,
          currentIntegration,
          monthData.labels[ind]
        );
      }
    },
  };

  const typeChartOptions = {
    onClick: (e, element) => {
      if (element.length > 0) {
        let ind = element[0]._index;
        setIsSecondDataTable(true);
        setIsThirdDataTable(true);
        fetchMonthData(
          true,
          control.getValues().dateStart,
          control.getValues().dateEnd,
          currentIntegration,
          objectTypeData.labels[ind],
          currentMonth
        );
      }
    },
  };

  return (
    <>
      <Grid item container spacing={1} sm={6} xs={12}>
        <Grid item md={6} sm={6} xs={12}>
          <ControlledInputRoundedForm
            id="dateStart"
            name="dateStart"
            label="Fecha inicio"
            control={control}
            fullWidth
            type="date"
            style={{ marginLeft: "10px", marginTop: "10px" }}
            error={errors.dateStart}
            shrink={true}
            helperText={errors.dateStart?.message}
          ></ControlledInputRoundedForm>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <ControlledInputRoundedForm
            id="dateEnd"
            name="dateEnd"
            label="Fecha fin"
            control={control}
            fullWidth
            type="date"
            shrink={true}
            style={{ marginLeft: "10px", marginTop: "10px" }}
            error={errors.dateEnd}
            helperText={errors.dateEnd?.message}
          ></ControlledInputRoundedForm>
        </Grid>
        <Grid
          item
          container
          spacing={2}
          sm={4}
          xs={12}
          style={{
            paddingLeft: "22px",
            paddingTop: "10px",
          }}
        ></Grid>
      </Grid>

      <Grid
        item
        container
        sm={3}
        xs={12}
        style={{
          paddingLeft: "20px",
          paddingTop: "15px",
        }}
      >
        <IconEspecialButton
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="contained"
          size={"large"}
          startIcon={<Search />}
          onClick={() => {
            handleDataWithDate();
          }}
        >
          Buscar
        </IconEspecialButton>
      </Grid>

      {isData && (
        <>
          <Grid item container spacing={1} sm={6} xs={12}>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Doughnut
                data={dashboardData}
                options={integrationChartOptions}
                height={240}
              />
              <label
                style={{
                  paddingTop: "15px",
                }}
              >
                Integraciones
              </label>
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {isMonthData && (
                <>
                  <Doughnut
                    data={monthData}
                    options={monthChartOptions}
                    height={240}
                  />
                  <label
                    style={{
                      paddingTop: "15px",
                    }}
                  >
                    {currentIntegration} por mes
                  </label>
                </>
              )}
            </Grid>
          </Grid>
          <Grid item container spacing={1} sm={6} xs={12}>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {isObjectTypeData && (
                <>
                  <Doughnut
                    data={objectTypeData}
                    options={typeChartOptions}
                    height={240}
                  />
                  <label
                    style={{
                      paddingTop: "15px",
                    }}
                  >
                    {currentIntegration} por evento
                  </label>
                </>
              )}
            </Grid>
          </Grid>
        </>
      )}

      <CustomTable
        columns={columns}
        mainParam={"name"}
        data={dataTable}
        option={option}
        setOption={setOption}
      />
      {isData && (
        <Grid
          item
          sm={1}
          xs={1}
          style={{
            alignSelf: "center",
            flexFlow: "row",
            paddingLeft: "20px",
          }}
        >
          <IconEspecialButton
            aria-controls="customized-menu"
            aria-haspopup="true"
            variant="contained"
            size={"small"}
            onClick={() => exportCSV(dataTable)}
            startIcon={<Launch />}
          >
            Exportar
          </IconEspecialButton>
        </Grid>
      )}
    </>
  );
}
