import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import ViewLayout from "../../components/layouts/ViewLayout";
import CustomTable from "../../components/Table/CustomTable.component";
import { Fab, Grid, makeStyles, Tooltip } from "@material-ui/core";
import NewUserIcon from "../../components/IconsLibrary/NewUserIcon";

//#region Entity's dependencies
import { getCustomForms, modifyCustomForm, deleteCustomForm, setEntity, setCustomFormUrl } from "../../actions/customform.action";
import CustomFormsForm from "./customFormsForm.view";
import { messagesResponseCustomForms } from "../../utils/constants";
import { SET_CUSTOMFORM_ERROR, GET_CUSTOMFORM_CHANGED } from "../../actions/types";
import { keys } from "lodash";
//#endregion

/**
 * CustomForms Component ( full view for list of CustomForms)
 *
 * @export Class Component
 * @class CustomForms
 * @extends {Component}
 * @returns Redux connect
 */
const useStyles = makeStyles((theme) => ({
  newUserIcon: {
    stroke: theme.palette.secondary.main,
  },
  styleFab: {
    boxShadow: "none",
  },
}));

const CustomForms = ({ component: Component, container, ...props }) => {
  
  const [entity, setObjectEntity] = useState("");
  const {
    records,
    recordsCount,
    getRecords,
    modifyRecord,
    deleteRecord,
    recordChanged,
    recordErrorResponse,
    messagesResponse,
    GET_RECORD_CHANGED,
    SET_RECORD_ERROR,
    currentEnterprise,
    Loading,
    entityDefault,
    onlyContent
  } = props;
  const classes = useStyles();

  //const [entity, setObjectEntity] = useState(entityDefault);
  if(entityDefault && entityDefault != entity){
    setObjectEntity(entityDefault);
  }
  const pathSplit = "/customforms/";
  if(!entityDefault && window.location.pathname.split(pathSplit).length && entity !== (window.location.pathname.split(pathSplit)[window.location.pathname.split(pathSplit).length - 1] ?? "")){
    setObjectEntity(window.location.pathname.split(pathSplit)[window.location.pathname.split(pathSplit).length - 1] ?? "");
  }

  //#region States
  const [showRecordAlert, setShowRecordAlert] = useState(false);
  const [recordId, setRecordId] = useState(0);
  const [recordInfo, setRecordInfo] = useState({});
  const dispatch = useDispatch();
  const [confirmInactivate, setConfirmInactivate] = useState({
    open: false,
    item: "",
    name: "",
    checked: false,
    message: "",
  });
  const [isFormShowed, setFormShowed] = useState(false);
  const [option, setOption] = useState(0);
  const [queriesColumns, setQueriesColumns] = useState([]);
  const [pageOffset, setPageOffset] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState(null);
  const [filtersText, setFiltersText] = useState("");
  const [refreshTable, setRefreshTable] = useState(false);
  const [columns, setColumns] = useState([{
    id: "idstate",
    label: "Estado",
    minWidth: 140,
    haveDropDown: true,
    align: "left",
    component: {
      handleChange: (e, value, id) => handleStateClick(e, value, id),
    },
    tooltipMessage: "registro",
    withIcon: true,
    handleIconClick: (ev, id) => handleDeleteClick(ev, id),
    format: (value) => (value === 1 || value === false ? false : true),
  }]);
  let [isLoading, setLoading] = useState(false);
  /*if(Loading !== undefined){
    setLoading = Loading;
  }*/
  //#endregion

  //#region Effects
  useEffect(() => {
    if (!Array.isArray(recordChanged)) {
      setConfirmInactivate({
        open: true,
        message: recordChanged,
        item: "inactivate",
      });
      dispatch({
        type: GET_RECORD_CHANGED,
        payload: [],
      });
    }
    if (!Array.isArray(recordErrorResponse) && recordErrorResponse !== messagesResponse.notPermission) {
      setConfirmInactivate({
        open: true,
        message: recordErrorResponse,
        showBtnAccept: true,
        textButtonAccept: "Cerrar",
        item: "inactivate",
      });
      dispatch({
        type: SET_RECORD_ERROR,
        payload: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordChanged, dispatch, recordErrorResponse]);

  useEffect(() => {
    if(entity && currentEnterprise?.id){
      if(currentEnterprise.monitoringService){
        setCustomFormUrl(`${currentEnterprise.monitoringService.replace("/api","")}/api/cruddata`);
      }
      setEntity(entity);
      setFilters(null);
      setFiltersText("");
      setPageOffset(0);
      setPageSize(10)
      getRecords(null, 0, 10);
      setRefreshTable(true);
      setLoading(true);
      setFormShowed(false);
    }
  }, [getRecords, /*isFormShowed*/, entity, currentEnterprise]);

  useEffect(() => {
    if(refreshTable){
      setRefreshTable(false);
    }
  }, [refreshTable]);

  useEffect(() => {
    if(isLoading){
      sleep(40000).then(r =>{
        setLoading(false);
      })
    }
  }, [isLoading]);

  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  useEffect(() => {
    setLoading(false);
    if (records?.length  /*&& columns.length === 1*/) {
      const newColumns = [];
      const newQueriesColumns = [];
      keys(records[0]).forEach((key)=>{
        if(key !== "createdby" 
            && key !== "createdon"
            && key !== "idbusinessunit"
            && key !== "idowner"
            && key !== "idstate"
            && key !== "modifiedby"
            && key !== "modifiedon"
            && key !== "idNavigation"
        ){
          const queryKey = key.charAt(0).toUpperCase() + key.slice(1);
          newColumns.push({
            id: key,
            label: queryKey,
            minWidth: 40,
            alignHeader: "left",
            haveDropDown: true,
            wordLength: 100,
          });
          let type = typeof(records[0][key]);
          newQueriesColumns.push({
            fieldName: queryKey,
            fieldValue: "",
            comparision: "6",
            groupAction: "9",
            type: type
          });
        }
      });
      newColumns.push({
        id: "action",
        label: "",
        align: "left",
        component: {
          handleChange: (e, value, id) => handleStateClick(e, value, id),
        },
        tooltipMessage: "registro",
        withStateIconByValue: true,
        withDeleteIconAlwaysVisible: true,
        withIcon: true,
        handleIconClick: (ev, id) => handleDeleteClick(ev, id),
        format: (value) => (value === 1 || value === false ? false : true),
      });
      setColumns(newColumns);
      setQueriesColumns(newQueriesColumns);
      records.forEach((rec, index)=>{
        rec.id = rec.id ?? index+1;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [records]);
  //#endregion

  //#region custom

  /**
   * Manage the click event for of change state
   * @param {*} e
   * @param {*} id
   * @param {*} name
   */
  const handleStateClick = (e, id, name) => {
    setConfirmInactivate({
      open: true,
      item: id,
      name: name,
      showBtnAccept: true,
      showBtnCancel: true,
      checked: e.target.checked,
      message: `¿Está seguro que desea ${
        e.target.checked ? "activar" : "inactivar"
      }
      ${"este registro"}?`,
    });
  };

  /**
   * Manage the click event of the delete icon
   * @param {*} ev
   * @param {*} id
   */
   const handleDeleteClick = (ev, id) => {
    setConfirmInactivate({
      open: true,
      item: id,
      showBtnAccept: true,
      showBtnCancel: true,
      delete: true,
      message: '¿Deseas eliminar este registro?',
    });
  };

  /**
   * Manage the click event of the confirmation or not of the popup
   */
  const handleConfirmChecked = () => {
    let result = records.filter(
      (item) => item.id === confirmInactivate.item
    )[0];

    if(result){
      let recordToBeModified = {
            ... result
          };
      console.log(confirmInactivate);
      if (confirmInactivate.name === "idstate") {
        recordToBeModified.idstate = confirmInactivate.checked ? 0 : 1;
        modifyRecord(
          recordToBeModified,
          updateChecked,
          true
        );
      } else if (confirmInactivate.delete) {
        recordToBeModified.idstate = 2;
        deleteRecord(
          recordToBeModified,
          updateChecked,
          true
        );
      }
    }
    setConfirmInactivate({
      ...confirmInactivate,
      open: false,
      item: "",
      checked: false,
      message: "",
    });
  };

  /**
   * Get the buttons definations of the custom table
   */
  const getButtons = () => {
    let button = {
      searchBar: {
        cellSize: {
          sm: 7,
          xs: 12,
        },
      },
      searchButton: true,
      menuOrder: {
        options: [
          {
            label: "Más reciente",
            columns: [
              {
                prop: "id",
                direction: 1
              },
            ],
          },
          {
            label: "Más antiguo",
            columns: [
              {
                prop: "id",
                direction: -1
              },
            ],
          },
        ],
        cellSize: {
          sm: 3,
          xs: 12,
        },
      },
      children: () => {
        return (
          <Grid container item lg={12} md={12} sm={2} xs={2} direction="row-reverse">
              <Tooltip title={`Crear`} aria-label={`Nuevo`} >
                <Fab
                  size="small"
                  className={[classes.fab, classes.styleFab]}
                  onClick={() => {
                    setFormShowed(true);
                    setRecordId(0);
                    setRecordInfo({... records[0]});
                  }}
                >
                  <NewUserIcon />
                </Fab>
              </Tooltip>
          </Grid>
        );
      },
    };

    return button;
  };

  /**
   * if record is updated, this method get records again
   */
  const updateChecked = (record) => {
      getRecords(filters, pageOffset, pageSize);
  };

  /**
   * Manage the click event of the selected cell
   */
  const handleCellClick = (evt, row) => {
    setRecordId(row.id);
    setRecordInfo(row);
    setFormShowed(true);
    setLoading(true);
  };

  /**
   * Manage the click event of change page or rows per page
   */
   const handleChangePageOrRows = (page, rowsPerPage) => {
    setPageOffset(page * rowsPerPage);
    setPageSize(rowsPerPage); 
    getRecords(filters, page * rowsPerPage, rowsPerPage);
    setLoading(true);
  };

  /**
   * Manage the query event
   */
   const filteredDataHandle = (search) => {
    setFiltersText(search);
    if(search?.length > 0){
      const queries = [];
      const hasAND = search.split(" AND ").length > 1;
      search.split(" AND ").forEach((searchAND)=>{
        const hasOR = searchAND.split(" OR ").length > 1;
        const lengthOR = searchAND.split(" OR ").length;
        searchAND.split(" OR ").forEach((searchOR, i)=>{
           if(searchOR.split("!=").length > 1){
            queries.push({
              fieldName: searchOR.split("!=")[0].trim(),
              fieldValue: searchOR.split("!=")[1].trim(),
              comparision: "1",
              groupAction: hasOR && i < (lengthOR - 1) ? "9" : hasAND ? "10" : "10",
              type: "string"
            });
          } else if(searchOR.split(">=").length > 1){
            queries.push({
              fieldName: searchOR.split(">=")[0].trim(),
              fieldValue: searchOR.split(">=")[1].trim(),
              comparision: "3",
              groupAction: hasOR && i < (lengthOR - 1) ? "9" : hasAND ? "10" : "10",
              type: "string"
            });
          } else if(searchOR.split("<=").length > 1){
            queries.push({
              fieldName: searchOR.split("<=")[0].trim(),
              fieldValue: searchOR.split("<=")[1].trim(),
              comparision: "5",
              groupAction: hasOR && i < (lengthOR - 1) ? "9" : hasAND ? "10" : "10",
              type: "string"
            });
          } else if(searchOR.split("*=").length > 1){
            queries.push({
              fieldName: searchOR.split("*=")[0].trim(),
              fieldValue: searchOR.split("*=")[1].trim(),
              comparision: "6",
              groupAction: hasOR && i < (lengthOR - 1) ? "9" : hasAND ? "10" : "10",
              type: "string"
            });
          } else if(searchOR.split("^=").length > 1){
            queries.push({
              fieldName: searchOR.split("^=")[0].trim(),
              fieldValue: searchOR.split("^=")[1].trim(),
              comparision: "7",
              groupAction: hasOR && i < (lengthOR - 1) ? "9" : hasAND ? "10" : "10",
              type: "string"
            });
          } else if(searchOR.split("$=").length > 1){
            queries.push({
              fieldName: searchOR.split("$=")[0].trim(),
              fieldValue: searchOR.split("$=")[1].trim(),
              comparision: "8",
              groupAction: hasOR && i < (lengthOR - 1) ? "9" : hasAND ? "10" : "10",
              type: "string"
            });
          } else if(searchOR.split("=").length > 1){
            queries.push({
              fieldName: searchOR.split("=")[0].trim(),
              fieldValue: searchOR.split("=")[1].trim(),
              comparision: "0",
              groupAction: hasOR && i < (lengthOR - 1) ? "9" : hasAND ? "10" : "10",
              type: "string"
            });
          } else if(searchOR.split(">").length > 1){
            queries.push({
              fieldName: searchOR.split(">")[0].trim(),
              fieldValue: searchOR.split(">")[1].trim(),
              comparision: "2",
              groupAction: hasOR && i < (lengthOR - 1) ? "9" : hasAND ? "10" : "10",
              type: "string"
            });
          } else if(searchOR.split("<").length > 1){
            queries.push({
              fieldName: searchOR.split("<")[0].trim(),
              fieldValue: searchOR.split("<")[1].trim(),
              comparision: "4",
              groupAction: hasOR && i < (lengthOR - 1) ? "9" : hasAND ? "10" : "10",
              type: "string"
            });
          } 
        });
      });

      if(queries.length){
        setFilters(queries);
        getRecords(queries, 0, pageSize);
      } else {
        queriesColumns.forEach((column)=>{
          column.fieldValue = 
            column.type == "string" ? String(search.toLowerCase()) 
            : column.type == "number" ? Number(search.toLowerCase())
            : column.type == "boolean" ? Boolean(search.toLowerCase()) 
            : search.toLowerCase();
        });
        setFilters(queriesColumns.filter(q=>!Number.isNaN(q.fieldValue)));
        getRecords(queriesColumns.filter(q=>!Number.isNaN(q.fieldValue)), 0, pageSize);
      }
      setPageOffset(0);
      setLoading(true);
    } else {
      setFilters(null);
      setLoading(true);
      setPageOffset(0);
      getRecords(null, pageOffset, pageSize);
    }
  };

  return (
    <>
    {onlyContent ? (
      <>
        {isFormShowed ? (
          <CustomFormsForm
            recordId={recordId}
            record={recordInfo}
            setRecordId={setRecordId}
            setCardState={setFormShowed}
            updateChecked={updateChecked}
            setLoading={setLoading}
            setShowRecordAlert={setShowRecordAlert}
            entity={entity}
          />
        ) : !refreshTable && (
          <CustomTable
            columns={columns}
            data={records}
            mainParam={"name"}
            buttons={getButtons()}
            havePagination={true}
            handleCellClick={handleCellClick}
            option={option}
            setOption={setOption}
            paramToDisable={"idstate"}
            filteredDataHandle={filteredDataHandle}
            customMaxLength={pageSize - 1}
            recordsCount={recordsCount}
            handleChangePageOrRows={handleChangePageOrRows}
            pageOffset={pageOffset / pageSize}
            pageSize={pageSize}
            filtersText={filtersText}
          />
        )}
      </>
    ) : (
      <ViewLayout
        headerTitle={entity}
        handleAccept={handleConfirmChecked}
        confirmInactivate={confirmInactivate}
        setConfirmInactivate={setConfirmInactivate}
        showAlert={showRecordAlert}
        setShowAlert={setShowRecordAlert}
        isLoading={isLoading}
      >
        {isFormShowed ? (
          <CustomFormsForm
            recordId={recordId}
            record={recordInfo}
            setRecordId={setRecordId}
            setCardState={setFormShowed}
            updateChecked={updateChecked}
            setLoading={setLoading}
            setShowRecordAlert={setShowRecordAlert}
            entity={entity}
          />
        ) : !refreshTable && (
          <CustomTable
            columns={columns}
            data={records}
            mainParam={"name"}
            buttons={getButtons()}
            havePagination={true}
            handleCellClick={handleCellClick}
            option={option}
            setOption={setOption}
            paramToDisable={"idstate"}
            filteredDataHandle={filteredDataHandle}
            customMaxLength={pageSize - 1}
            recordsCount={recordsCount}
            handleChangePageOrRows={handleChangePageOrRows}
            pageOffset={pageOffset / pageSize}
            pageSize={pageSize}
            filtersText={filtersText}
          />
        )}
      </ViewLayout>
    )}
    </>
  );
};

//#endregion

//#region entities's declarations 
CustomForms.propTypes = {
  getRecords: PropTypes.func.isRequired,
  modifyRecord: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  records: state.customFormsReducer.getCustomFormsResponse.records,
  recordsCount: state.customFormsReducer.getCustomFormsResponse.count,
  recordChanged: state.customFormsReducer.getCustomFormChangedResponse,
  recordErrorResponse: state.customFormsReducer.setCustomFormErrorResponse,
  currentEnterprise: state.enterpriseReducer.getEnterpriseByIDResponse,
  messagesResponse: messagesResponseCustomForms,
  GET_RECORD_CHANGED: GET_CUSTOMFORM_CHANGED,
  SET_RECORD_ERROR: SET_CUSTOMFORM_ERROR
});

const mapDispatchToProps = {
  getRecords: getCustomForms,
  modifyRecord: modifyCustomForm,
  deleteRecord: deleteCustomForm
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomForms);
//#endregion