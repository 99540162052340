import {
  GET_OBJECTTYPE,
  GET_OBJECTTYPES,
  GET_FORMATTYPES,
  GET_PROTOCOLTYPES,
  GET_SERVICETYPES,
  GET_SERVICECATEGORIES,
  GET_METHODTYPES,
  GET_SECURITYTYPES,
  GET_RESPONSECODETYPES,
  GET_INTEGRATIONACTIONS,
  GET_CUSTOMVIEWS,
  GET_SIGNATURESUPPLIER,
  SET_OBJECTTYPE_ERROR,
  GET_OBJECTTYPE_CHANGED,
} from "./types";
import { getRecords, getRecordsByCustomer, getRecordById, createRecord, modifyRecord, getRecordsByField } from "./base.action";

export /**
 * ObjectTypes Action ObjectTypes
 *
 * @method GET
 */
  const getObjectTypes = () => async (dispatch) => {
    dispatch(getRecords("objecttype", GET_OBJECTTYPES, SET_OBJECTTYPE_ERROR, 0));
  };

export /**
 * ObjectTypes Action getObjectTypes
 *
 * @method GET
 * @param customerid
 */
  const getObjectTypesByCustomer = (customerid) => async (dispatch) => {
    dispatch(getRecordsByCustomer("objecttype", customerid, GET_OBJECTTYPES, SET_OBJECTTYPE_ERROR, 0));
  };

export /**
 * ObjectType Action getObjectTypeById
 *
 * @method GET
 * @param {*} id
 */
  const getObjectTypeById = (id) => async (dispatch) => {
    dispatch(getRecordById("objecttype", id, GET_OBJECTTYPE, SET_OBJECTTYPE_ERROR, 0));
  };

export /**
 * ObjectTypes Action get Object Types of formats
 *
 * @method GET
 * @param 
 */
  const getFormatTypes = () => async (dispatch) => {
    dispatch(getRecordsByField("objecttype", "Object", "FormatTypes", GET_FORMATTYPES, SET_OBJECTTYPE_ERROR, 0));
  };

  export /**
  * ObjectTypes Action get Object Types of protocols
  *
  * @method GET
  * @param 
  */
   const getProtocolTypes = () => async (dispatch) => {
     dispatch(getRecordsByField("objecttype", "Object", "ProtocolTypes", GET_PROTOCOLTYPES, SET_OBJECTTYPE_ERROR, 0));
   };

   export /**
 * ObjectTypes Action get Object Types of services
 *
 * @method GET
 * @param 
 */
  const getServiceTypes = () => async (dispatch) => {
    dispatch(getRecordsByField("objecttype", "Object", "ServiceTypes", GET_SERVICETYPES, SET_OBJECTTYPE_ERROR, 0));
  };

  export /**
 * ObjectTypes Action get Object Types of services
 *
 * @method GET
 * @param 
 */
  const getServiceCategories = () => async (dispatch) => {
    dispatch(getRecordsByField("objecttype", "Object", "ServiceCategories", GET_SERVICECATEGORIES, SET_OBJECTTYPE_ERROR, 0));
  };

  export /**
 * ObjectTypes Action get Object Types of methods
 *
 * @method GET
 * @param 
 */
  const getMethodTypes = () => async (dispatch) => {
    dispatch(getRecordsByField("objecttype", "Object", "MethodTypes", GET_METHODTYPES, SET_OBJECTTYPE_ERROR, 0));
  };

  export /**
 * ObjectTypes Action get Object Types of security
 *
 * @method GET
 * @param 
 */
  const getSecurityTypes = () => async (dispatch) => {
    dispatch(getRecordsByField("objecttype", "Object", "SecurityTypes", GET_SECURITYTYPES, SET_OBJECTTYPE_ERROR, 0));
  };

  export /**
  * ObjectTypes Action get Object Types of response code types
  *
  * @method GET
  * @param 
  */
   const getResponseCodeTypes = () => async (dispatch) => {
     dispatch(getRecordsByField("objecttype", "Object", "ResponseCodeTypes", GET_RESPONSECODETYPES, SET_OBJECTTYPE_ERROR, 0));
   };

   export /**
   * ObjectTypes Action get Object Types of integration actions
   *
   * @method GET
   * @param 
   */
    const getIntegrationActions = () => async (dispatch) => {
      dispatch(getRecordsByField("objecttype", "Object", "IntegrationActions", GET_INTEGRATIONACTIONS, SET_OBJECTTYPE_ERROR, 0));
    };

   export /**
   * ObjectTypes Action get Object Types of custom views by enterprise name
   *
   * @method GET
   * @param 
   */
    const getCustomViewsByEnterprise = (enterpriseName) => async (dispatch) => {
      dispatch(getRecordsByField("objecttype", "Object", `${enterpriseName}-CustomView`, GET_CUSTOMVIEWS, SET_OBJECTTYPE_ERROR, 0));
    };

  export /**
  * ObjectTypes Action get Object Types of signature supplier
  *
  * @method GET
  * @param 
  */
    const getSignatureSupplier = () => async (dispatch) => {
      dispatch(getRecordsByField("objecttype", "Object", `SignatureSupplier`, GET_SIGNATURESUPPLIER, SET_OBJECTTYPE_ERROR, 0));
    };

export /**
  * ObjectType Action create ( create )
  *
  * @method POST
  * @param {history} history
  */
 const createObjectType = (recordToCreate, updateChecked) => async (dispatch) => {
    dispatch(createRecord("objecttype", recordToCreate, updateChecked, GET_OBJECTTYPE_CHANGED, SET_OBJECTTYPE_ERROR));
 };
 

export /**
 * ObjectType Action modify ( modify )
 *
 * @method PUT
 * @param {history} history
 */
const modifyObjectType = (recordToUpdate, updateChecked, isPrincipalPage, tab) => async (dispatch) => {
    dispatch(modifyRecord("objecttype", recordToUpdate, updateChecked, isPrincipalPage, GET_OBJECTTYPE_CHANGED, SET_OBJECTTYPE_ERROR));  
};
