export const MAX_NUMBER_ATTEMPS_ELECTRONIC = 3;
export const API_HOSTNAME ='https://8uw10ruhfj.execute-api.us-east-2.amazonaws.com/qa/fywebapi';
export const API_HOSTNAME_SIGNS = 'https://8uw10ruhfj.execute-api.us-east-2.amazonaws.com/qa/signdocument/';
export const API_HOSTNAME_AWS = 'https://8uw10ruhfj.execute-api.us-east-2.amazonaws.com/qa/';
export const MAX_MEGAS_FILE_SIZE = 10;
export const MAX_MEGAS_FILES_SIZE = 50;
export const API_HOSTNAME_GET_CERTIFICATE = 'https://8uw10ruhfj.execute-api.us-east-2.amazonaws.com/qa/fywebapi/api/Certificado/certificados';
export const API_HOSTNAME_GET_LIST_HANDWRITTE = 'https://8uw10ruhfj.execute-api.us-east-2.amazonaws.com/qa/fywebapi/api/Certificado/certificados';



export const MAX_MEGAS_ENCRYPT_SIZE = 2;
export const MAX_MEGAS_VALIDATE = 5;
export const MAX_RECIPIENT_ENCRYPT = 5;
export const MAX_FILE_UPLOAD_ENCRYPT = 2;
export const MAX_FILE_UPLOAD = 10;
export const APP_NAME = "firmaya";
export const URL_LOGIN = "https://qasecurity.firmaya.co/";
export const MAX_FILE_DOWNLOAD = 10;
export const MAX_FILE_DONWLOAD_DASHBOARD = 5;
export const ACCEPT_FILES = ['.pdf'];
export const ACCEPT_FILES_VALIDATE = ['.p7s', '.p7z', '.pdf', 'application/pkcs7-signature'];
export const ACCEPT_FILES_ENCRYPT = ['.mp4','.pdf', '.png', '.jpg', '.docx', '.doc'];
export const URL_LICENCIAMIENTO = "https://qalicence.firmaya.co";




export const API_LICENCIAMIENTO = 'https://jkcge88asd.execute-api.us-east-2.amazonaws.com/qa';

// google DRIVE
export const APL_GOOGLE_DRIVE_CLIENT_ID =
  "648154767685-igsmp2i3kh9pirvimd1tc78fho33mon5.apps.googleusercontent.com";
export const API_GOOGLE_DRIVE_API_KEY =
  "AIzaSyDjUoyS_ruocp-v-TfDJTl1skwsIL5Z3rw";
export const DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
];
export const SCOPES = ["https://www.googleapis.com/auth/drive.file"];
export const SCOPES_GET = ["https://www.googleapis.com/auth/drive"];
export const GET_INFO_DOC_GOOGLE_DRIVE =
  "https://www.googleapis.com/drive/v2/files/";

// DROPBOX
export const API_DROPBOX_APP_KEY = 'isz6n20qhewwlph';
export const API_DROPBOX_APP_SECRET = 'l1r4aufhlq8319l';
export const API_DROPBOX_TOKEN = 'sl.Al2m1kaZFR8LEH-Xinvi-sqhuI89jKwGS7wQhQcdDLncFdESWfegJcZ2KQeKz9PUddX7oUFP7i-pmJMS3VQ2saO0464E_5Re6RNhvWUnziOx-csaoHQkTyOyodLL5EFUpfDQHPsUHbg';
export const API_DROPBOX_URL_GET = 'https://content.dropboxapi.com/2/files/download';
export const API_DROPBOX_URL_POST = 'https://content.dropboxapi.com/2/files/upload';

// ONEDRIVE
export const API_ONEDRIVE_APP_CLIENT_ID = '53a6eafb-2fee-4ece-899e-7d61bd327dfc';
export const API_ONEDRIVE_REDIRECT = 'https://qaweb.firmaya.co/';

//FirmaYa Desktop
export const URL_MAN_USUARIO = 'https://firmaya.co/driver/Manual_Firmaya_Desktop.pdf';
export const URL_MAN_INSTALACION = 'https://drive.google.com/file/d/16wc2Nu7QkAUgT4pXjQz_mqIztm9c_KX_/view';
export const URL_FIRMAYA_DESKTOP = 'https://firmaya.co/driver/GSEfirmaya.exe';
export const URL_FIRMAYA_DRIVER = 'https://drive.google.com/file/d/1m49zPoW4pEA92NSrGNq_nFe7RJzcGbw3/view?usp=sharing';
export const URL_FIRMAYA_DRIVERx64 = 'https://firmaya.co/driver/FirmayaDriver_x64.zip';
export const URL_FIRMAYA_DRIVERx86 = 'https://firmaya.co/driver/FirmayaDriver_x86.zip';
