import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import FormLayout from "../../components/layouts/FormLayout";
import { isEmpty } from "../../utils/proprietaryHooks";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import TabComponent from "../../components/TabsComponent/TabComponent";
import {Grid, MenuItem} from "@material-ui/core";
import MonitoringView from "./monitoring.view";
import CustomForms from "../customForms/customForms.view";
import { MonitoringDashBoard } from "./monitoringDashboard";
import ControlledInputRoundedForm from "../../components/InputForm/ControlledInputRoundedForm";

//#region Entity's dependencies

//#endregion

/**
 * TracingForm Component ( full view for form to create/modify integration )
 *
 * @export Class Component
 * @extends {Component}
 * @returns Redux connect
 */
const TracingForm = (props) => {
  /* #region  Props */
  const {
    userPrivileges,
    enterpriseRecord
  } = props;

  const[objectTypes] = useState([
    {id:"pifexportedmetadata", name:"Datos Maestros", selected:false, filter:""}
    ,{id:"pifexportedsupplier", name:"Proveedores", selected:false, filter:""}
    ,{id:"pifexportedrequisition", name:"Solicitudes", selected:false, filter:""}
    ,{id:"pifexportedpurchaseorder", name:"Ordenes de compra", selected:false, filter:""}
    ,{id:"pifexportedinvoice", name:"Facturas", selected:false, filter:""}
    ,{id:"pifeventlog", name:"Log de eventos", selected:true, filter:""}
  ]);
  /* #endregion */

  /* #region  States */
  const [tab, setTab] = useState(0);
  const [confirmInactivate, setConfirmInactivate] = useState({
    open: false,
    checked: false,
    back: false,
    cancelEdit: false,
    changeTab: false,
    newTab: 0,
  });
  /* #endregion */

  //#region react hook form config
  const requiredMessage = "Campo obligatorio.";
  const validationSchema = Yup.object().shape({
    objectType: Yup.string().required(requiredMessage),
  });
  const {
    control,
    watch,
  } = useForm({
    defaultValues: {
      objectType: "pifeventlog"
    },
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
    mode: "onChange",
  });
  //#endregion

  /* #region  useEffect */
  useEffect(() => {
    const objectTypeSelected = watch("objectType");
    if (objectTypeSelected !== "") {
      console.log(objectTypeSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("objectType")]);
  /* #endregion */

  const handleChangeTab = async (event, newTab) => {
    event.preventDefault();
    setTab(newTab);
  };

  const handleAccept = (e) => {
    setConfirmInactivate((state) => ({
      ...state,
      open: false,
    }));
  };

  const defineSubHeader = () => {
    let subheader = "";
    switch (tab) {
      case 0:
        subheader = "Panel de control";
        break;
      case 1:
        subheader = "Bitácora de errores";
        break;
      case 2:
        subheader = "Detalle de transacciones";
        break;
      default:
        subheader = "Nuevo monitoreo";
        break;
    }
    return subheader;
  };

  const childrenForm = [
    {
      label: "Panel de control",
      tabContent: (
        <Grid container style={{
            display: "flex",
          }}>
          <MonitoringDashBoard />
        </Grid>
      ),
    },
    {
      label: "Bitácora de errores",
      tabContent: (
        <Grid container style={{
          display: "flex"
        }}>
          <MonitoringView onlyContent={true}/>
        </Grid>
      ),
    },
    {
      label: "Detalle de transacciones",
      tabContent: (
        <Grid container style={{
          display: "flex"
        }}>
          <Grid
            item
            sm={4}
            xs={12}
            style={{ marginRight: "10px" }}
          >
            <ControlledInputRoundedForm
              id="objectType"
              name="objectType"
              label="Tipo de transacción"
              control={control}
              fullWidth
              select
              style={{ marginLeft: "10px", marginTop: "10px" }}
            >
              {objectTypes.map((option) => (
                <MenuItem
                  key={option.id}
                  value={option.id}
                  selected={option.selected}
                >
                  {option.name}
                </MenuItem>
              ))}
            </ControlledInputRoundedForm>
          </Grid>
          <CustomForms entityDefault={watch("objectType")} onlyContent={true} />
        </Grid>
      ),
    }
  ];

  return (
    <div>
      <FormLayout
        handleSubmit={(e) => e.preventDefault()}
        //subheaderBase={enterpriseRecord.name}
        //subheader={defineSubHeader()}
        isEditing={false}
        confirmInactivate={confirmInactivate}
        setConfirmInactivate={setConfirmInactivate}
        modalMessage={`¿Desea modificar esta configuración?`}
        handleAccept={(e) => handleAccept(e)}
      >
        <TabComponent
          aria={"tracings tabs"}
          handleChangeTab={handleChangeTab}
          tab={tab}
          children={childrenForm}
        />
      </FormLayout>
    </div>
  );
};

//#region entities's declarations 
TracingForm.propTypes = {
  
};

const mapStateToProps = (state) => ({
  
});

const mapDispatchToProps = {
  
};

export default connect(mapStateToProps, mapDispatchToProps)(TracingForm);
//#endregion